import React, { Component } from 'react';
import _ from 'lodash';

import { authUserState } from '../stores/common';

import { CheckListReportWidget, CheckListWidget, TaskListWidget } from './CheckList';
import { ReleaseWidget } from './Release';
import { TransferWidget } from './Transfers';
import { checkRights } from '../functions';

export class DashboardPage extends Component {
	render() {
		var widgets = !_.isUndefined(authUserState.Dashboards) ? _.map(authUserState.Dashboards.Widget, "Group") : [],
			availableWidgets = ["CheckList", "Release", "Movement"];

		return (
			<div className="page" id="DashboardPage">
				<div className="head"><h2>Рабочий стол</h2></div>
				<section>
					{_.map(widgets, function(item) {
						if (_.indexOf(availableWidgets, item) === -1) return null;
						
						return(
							<>
								{item === "CheckList" && <TaskListWidget key={item} />}
								{item === "Release" && <ReleaseWidget key={item} />}
								{item === "Movement" && <TransferWidget key={item} />}
							</>
						)
					})}
					{authUserState.HasCheckList && !authUserState.HasERP && checkRights("ReportCheckList") &&
						<CheckListReportWidget key="CheckListrep" />
					}
					{authUserState.HasCheckList && !authUserState.HasERP && checkRights("ManageCheckList") &&
						<CheckListWidget key="CheckListset" />
					}
				</section>
			</div>
		)
	}
}