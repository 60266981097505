import { getRequest } from "../functions";

/** API метод «Filials», отдающий список доступных филиалов пользователя */
export function getFilialsList(params = {}, callback) {
	getRequest("Filials", params, (data) => { if (callback) callback(data); });
}

/** API метод «Users», отдающий список пользователей */
export function getUsersList(params = {}, callback) {
	getRequest("Users", params, (data) => { if (callback) callback(data); });
}

/** API метод «Roles», отдающий список должностей */
export function getRolesList(callback) {
	getRequest("Roles", {}, (data) => { if (callback) callback(data); });
}

//** ----- МЕТОДЫ РАБОТЫ С АККАУНТОМ ПОЛЬЗОВАТЕЛЯ ----- */
/** */
export function getAccount(params = {}, callback) {
	getRequest("Account", params, (data) => { if (callback) callback(data); }); 
}

//** ----- МЕТОДЫ РАБОТЫ С ЧЕК ЛИСТАМИ ----- */
/** API метод «Checklists», отдающий список чек листов */
export function getCheckLists(params = {}, callback) {
	getRequest("Checklists", params, (data) => { if (callback) callback(data); });
}
/** API метод «Checklist», отдающий чек лист */
export function getCheckListbyId(id, callback) {
	getRequest("CheckList", { Id : id }, (data) => { if (callback) callback(data); });
}
/** API метод «ChecklistRemove», удаляющий чек лист */
export function removeCheckListbyId(id, callback) {
	getRequest("ChecklistRemove", { Id : id }, (data) => { if (callback) callback(data); });
}
/** API метод «ChecklistSave», для сохранения чек листа */
export function saveCheckList(params, callback) {
	getRequest("ChecklistSave", params, (data) => { if (callback) callback(data); });
}

/** API метод «TasksList», отдающий список задач на сегодня */
export function getTasks(callback) {
	getRequest("TasksList", {}, (data) => { if (callback) callback(data); });
}
/** API метод «DoneTask», выполняющий задачу */
export function doneTaskbyId(id, callback) {
	getRequest("DoneTask", { Id : id }, (data) => { if (callback) callback(data); });
}