import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import _ from 'lodash';

import EmptyCheckListImage from '../images/emptyCheckList.svg';

import { NavLink } from 'react-router-dom';
import { TextInput, CheckGroup, PeriodTime, Selectize, DateInput, CheckFilter, DatePreset } from '../forms/FormItems';
import { ItemHeader, TablePagination, TableReportPage } from './PageTemplates';

import { errors, daysShort, buttonTitles } from './../dictionary';
import { toSelectList, getRequest, formatDate, sendMessageRequest } from './../functions';

import { checkListState, checkListReportState, checkListWidgetState, checkListTaskState, taskListWidgetState, checkListReportWidgetState } from '../stores/checklists';
import { authUserState, uistate, confirmState, errorState } from '../stores/common';
import { AddIcon, ArrowIcon, CheckIcon, CopyIcon, DeleteIcon, FilterIcon, InfoIcon, StatBestIcon, StatDoneIcon, StatExpiredIcon, StatFailedIcon, StatIcon, StatLikeIcon, StatListIcon, StatTaskIcon } from '../images/icons';
import { doneTaskbyId, getAccount, getCheckListbyId, getCheckLists, getFilialsList, getRolesList, getTasks, getUsersList, removeCheckListbyId, saveCheckList } from '../stores/apiMethods';

/**
 * СТРАНИЦА НАСТРОЙКИ ЧЕК ЛИСТОВ
 * На данной странице происходит настройка шаблонов чек листов.
 * Для настройки чек листов у пользователя должно быть право «ManageCheckList»
 * Чек листы можно настраивать только в десктопной версии
 * 
 ** /api/Checklists – метод, возвращающий список имеющихся чек листов
 ** /api/Filials – метод, возвращающий список филиалов
 ** /api/Roles – метод, возвращающий список должностей
 ** /api/Users – метод, возвращающий список пользователей
 ** /api/CheckList – метод, возвращающий настройки чек листа по его ID
 */

export class CheckListManagePage extends Component {
	constructor(props) {
		super(props);

		if (/new/.test(window.location.search)) {
			checkListState.setList({ Id : -1 });
			this.props.history.push('/CheckLists');
		}

		this.getData();

		getAccount({}, (data) => {
			if (data.Success) checkListState.MessageParams.UserPhone = data.Login;
		});

		getFilialsList({}, (data) => {
			checkListState.Filials = data.Success && !_.isNull(data.Filials) ? data.Filials : [];
		});
		getUsersList({ Active : true }, (data) => { 
			checkListState.Users = data.Success && !_.isNull(data.Users) ? data.Users : []; 
		});
		getRolesList((data) => { 
			checkListState.Roles = data.Success && !_.isNull(data.Roles) ? data.Roles : []; 
		});
		getCheckLists({ Templates : true }, (data) => { checkListState.setTemplates(data); })
	}

	/** API запрос «Checklists» для получения списка чек листов */
	getData() {
		checkListState.IsSendRequest = false;
		getCheckLists({}, (data) => { checkListState.setLists(data); });
	}

	/**
	 * API запрос «CheckList» на получение чек-листа
	 * @param {number} id ID чек листа
	 * @param {string} type тип действия
	 */
	getItem(id, type) {
		getCheckListbyId(id, (data) => { checkListState.setList(data.Success ? data : undefined, type); });
	}

	/**
	 * Выбор чек-листа из списка
	 * @param {number} id ID чек листа
	 * @param {string} type тип действия
	 */
	setId(id, type) { 
		if (!_.isUndefined(id)){ 
			if (id !== -1) this.getItem(id, type);
			else checkListState.setList({ Id : -1 });
		} else {
			checkListState.setList(undefined)
			this.getData();
		}
	}

	/**
	 * API запрос «ChecklistRemove» на удаление чек листа
	 * @param {number} id ID чек-листа
	 */
	removeId(id) { 
		if (_.isUndefined(id)) return false;

		var item = _.find(checkListState.Lists, { Id : id }),
			text = <span>Вы действительно хотите удалить чек-лист <b key="b1">«{item ? item.Name : ""}»</b>?</span>;

		confirmState.openConfirm(text, () => { removeCheckListbyId(id, () => this.getData()); });
	}

	/** Окно подтверждения заявки на настройку */
	handleClick() {
		var title = "Помочь с настройкой?",
			text = "Мы предлагаем бесплатную услугу по настройке системы под ваши задачи. Оставьте заявку и мы свяжемся с вами в ближайшее время чтобы уточнить детали.";
		
		confirmState.openConfirm(title, () => {
			sendMessageRequest(checkListState.MessageParams, (data) => {
				if (data.contact) errorState.setError(undefined, undefined, "Ваша заявка отправлена, мы скоро с вами свяжемся", "success");
				else errorState.setError(undefined, undefined, "Что то пошло не так. Попробуйте еще раз или напишите на support@ectrum.ru");
			})
		}, text)
	}

	render() {
		return (
			<div className={"page " + (uistate.IsMobile ? "mobile" : "")} id="CheckListManagePage">
				{_.isUndefined(checkListState.List) &&
					<section>
						<div className="head">
							<h2>Мои чек листы</h2>
							{checkListState.CanManage && !uistate.IsMobile &&
								<div className='buttons'>
									<button onClick={(e) => { this.setId(-1) }}>Создать чек лист</button>
									{/* {!authUserState.HasERP && */}
										<button className='green' onClick={this.handleClick.bind(this)}>Заказать настройку</button>
									{/* } */}
								</div>
							}
							{uistate.IsMobile &&
								<p>Редактирование чек-листов доступо только на десктопной версии или на планшете</p>
							}
						</div>
						{_.isEmpty(checkListState.Lists) && checkListState.IsSendRequest &&
							<p>По вашему запросу ничего не найдено</p>
						}
						{!_.isEmpty(checkListState.Lists) && 
							<TableReportPage
								Type={this.props.Type}
								TableData={checkListState.Lists}
								TableColumns={checkListState.TableListColumns}
								TableFilterable={true}
		
								editRow={this.setId.bind(this)}
								removeRow={this.removeId.bind(this)}
							/>
						}
						{!_.isEmpty(checkListState.Templates) &&
							<>
								<h2>Готовые шаблоны чек листов</h2>
								<TableReportPage
									Type={this.props.Type}
									TableData={checkListState.Templates}
									TableColumns={checkListState.TableTemplateColumns}
			
									editRow={this.setId.bind(this)}
								/>	
							</>
						}
					</section>
				}
				{!_.isUndefined(checkListState.List) && 
					<CheckListItem setId={this.setId.bind(this)} />
				}
			</div>
		)
	}
}
CheckListManagePage = observer(CheckListManagePage);

class CheckListItem extends Component {
	/** Закрытие окна редактирования чек-листа */
	handleClose (event) {
		event.preventDefault();
		this.props.setId(undefined);
	}

	/** API запрос «ChecklistSave» на сохранение чек листа */
	handleSubmit (event) {
		event.preventDefault();

		if (!checkListState.validateData()) return false;

		checkListState.ButtonLoading = true;

		saveCheckList(checkListState.List.getListtoRequest(), (data) => {
			checkListState.ErrorCode = data.Success ? "SUCCESS_SAVE" : data.ErrorCode;
			checkListState.SuccessSave = data.Success;
			checkListState.List.Id = data.Success ? data.Id : -1;
			checkListState.ButtonLoading = false;
		});
	}

	render() {
		return (
			<section className="clearfix item" id="CheckListItem">
				<form onSubmit={this.handleSubmit.bind(this)} >
					<ItemHeader 
						Title={buttonTitles[(checkListState.List.Id === -1 ? "New" : "Edit") + "CheckList"]}
						Error={checkListState.ErrorCode}
						Loading={checkListState.ButtonLoading}
						CanManage={checkListState.CanManage && !uistate.IsMobile}
						Success={checkListState.SuccessSave}
						handleClose={this.handleClose.bind(this)}
					/>
					<CommonSettings />
					<GropsBlock />
					{/* <div className="section">
						
					</div> */}
				</form>
			</section>
		)
	}
}
CheckListItem = observer(CheckListItem);

class CommonSettings extends Component {
	render() {
		return(
			<div className='section main'>
				<MainInfo />
				<ListMerchantSetting />
			</div>
		)
	}
}

class ListMerchantSetting extends Component {
	render() {
		return(
			<div>
				<p className='comment'>Вы можете привязать чек лист к конкретной должности, филиалу или пользователю. Если вы укажете значения чек лист будет доступен только этим исполнителям, если вы оставите эти поля не заполненными, чек лист будет доступен всем пользователям</p>
				<Selectize
					Id="RoleId"
					Title="Должность"
					List={toSelectList(checkListState.Roles)}
					Disabled={uistate.IsMobile}
					Value={checkListState.List.RoleId}
					onChange={(value,id) => checkListState.List.collect(id, value)}
				/>
				<Selectize
					Id="FilialId"
					Title="Филиал"
					List={toSelectList(checkListState.Filials)}
					Disabled={uistate.IsMobile}
					Value={checkListState.List.FilialId}
					onChange={(value,id) => checkListState.List.collect(id, value)}
				/>
				<Selectize
					Id="UserId"
					Title="Пользователь"
					List={toSelectList(checkListState.UsersList)}
					Disabled={uistate.IsMobile}
					Value={checkListState.List.UserId}
					onChange={(value,id) => checkListState.List.collect(id, value)}
				/>
			</div>
		)
	}
}
ListMerchantSetting = observer(ListMerchantSetting);

class MainInfo extends Component {
	render() {
		return(
			<div>
				<TextInput
					Id="Name"
					Title="Название"
					Required={true}
					Value={checkListState.List.Name}
					Disabled={uistate.IsMobile}
					onChange={(value,id) => checkListState.List.collect(id, value)}
				/>
				<CheckGroup
					Id="Required"
					Type="radio"
					Title="Назначение задач пользователю"
					List={checkListState.RequiredList}
					Value={checkListState.List.Required ? ["true"] : ["false"]}
					Disabled={uistate.IsMobile}
					onChange={(value,id) => checkListState.List.collect(id, value)}
				/>
			</div>	
		)
	}
}
MainInfo = observer(MainInfo);

class GropsBlock extends Component {
	/** Добавление группы задач */
	handlerAdd (event) { 
		event.preventDefault();
		checkListState.List.addGroup();
	}

	render() {
		return(
			// <div className="groups">
			<>
				{_.map(checkListState.List.Groups, (item,i) => {
					return (<GroupItem key={"gr" + i} Item={item} Index={i} />)
				})}
				{!uistate.IsMobile &&
					<a className="button iconSvg" href="/" onClick={this.handlerAdd}>
						<AddIcon />Добавить подраздел</a>
				}
			</>
			// </div>
		)
	}
}
GropsBlock = observer(GropsBlock);

class GroupItem extends Component {
	/** Удаление группы задач */
	handleDelete (event) { 
		event.preventDefault();
		checkListState.List.deleteGroup(this.props.Index);
	}

	/** Добавление задачи */
	handlerAdd (event) { 
		event.preventDefault();
		checkListState.List.addTask(this.props.Index);
	}

	render() {
		// TODO режим чтения - мне не нравится

		return (
			<div className="grouptask section">
				<div className="grouptitle">
					{!uistate.IsMobile &&
						<>
							<TextInput
								Id="Group"
								Title="Название подраздела"
								Required={true}
								Disabled={uistate.IsMobile}
								Value={this.props.Item.Group}
								onChange={(value,id) => checkListState.List.collect(id,value,this.props.Index)}
							/>
							<a className="remove" href="/" onClick={this.handleDelete.bind(this)} title='Удалить подраздел'>
								<DeleteIcon />
							</a>
						</>
					}
					{uistate.IsMobile && <h4>{this.props.Item.Group}</h4>}
				</div>
				{!uistate.IsMobile &&
					<div className={"tasks " + (checkListState.List.Required ? "" : "simple")}>
						<h5>Задачи</h5>
						{checkListState.List.Required &&
							<>
								<h5>Периодичность и Время выполнения</h5>
								<h5>&nbsp;</h5>
							</>
						}
						<h5>&nbsp;</h5>
						{this.props.Item.Tasks.map((v,i) => {
							return(<TaskItemEdit Task={v} key={"ts" + checkListState.TaskCounter++} 
								GroupIndex={this.props.Index} Index={i}/>
							)
						}) }
					</div>
				}
				{uistate.IsMobile &&
					<div className='list'>
						{this.props.Item.Tasks.map((v,i) => {
							return(
								<TaskItemRead Task={v} key={"ts" + checkListState.TaskCounter++} 
									GroupIndex={this.props.Index} Index={i}/>
							)
						}) }
					</div>
				}
				{!uistate.IsMobile &&
					<a className="button iconSvg" href="/" onClick={this.handlerAdd.bind(this)}>
						<AddIcon />Добавить задачу</a>
				}
			</div>
		)
	}
}
GroupItem = observer(GroupItem)

class TaskItemEdit extends Component {
	/** Удаление задачи из списка */
	handleDelete (event) { 
		event.preventDefault();
		checkListState.List.deleteTask(this.props.GroupIndex, this.props.Index);
	}

	/** Копирование задачи в списке */
	handleCopy (event) { 
		event.preventDefault();
		checkListState.List.addTask(this.props.GroupIndex, this.props.Task);
	}

	render() {
		return(
			<>
				<div>
					<TextInput
						Id="Name"
						Title="Название задачи"
						Required={true}
						Value={this.props.Task.Name}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
					<TextInput
						Id="Description"
						Title="Описание задачи"
						Type="textarea"
						Value={this.props.Task.Description}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
					<CheckGroup
						Id={"Individual_" + this.props.Index + this.props.GroupIndex}
						Title="Индивидуальная задача"
						Type="radio"
						List={checkListState.IndividualList}
						Value={this.props.Task.Individual ? ["true"] : ["false"]}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
				</div>
				{checkListState.List.Required &&
					<>
						<TaskPeriodSetting Task={this.props.Task} GroupIndex={this.props.GroupIndex} Index={this.props.Index} />
						<CheckFilter
							Id="Push"
							Title="PUSH"
							Value={this.props.Task.Push}
							onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
						/>
					</>
				}
				<div className='actions'>
					<a href="/" title="Дублировать задачу" className='iconSvg'
						onClick={this.handleCopy.bind(this)}><CopyIcon /></a>
					<a href="/" title="Удалить задачу" className="remove iconSvg" 
						onClick={this.handleDelete.bind(this)}><DeleteIcon /></a>
				</div>
			</>
		)
	}
}
TaskItemEdit = observer(TaskItemEdit);

class TaskPeriodSetting extends Component {
	render() {
		var isWeekly = this.props.Task.Type === "w",
			isMonthly = this.props.Task.Type === "m";

		return(
			<div>
				<div className='timePeriod'>
					<Selectize
						Id="Type"
						Title="Задача повторяется"
						Value={this.props.Task.Type}
						List={checkListState.TypeList}
						isClearable={false}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
					<TextInput
						Id="Period"
						Title={"Каждые N " + (isMonthly ? "месяцев" : isWeekly ? "недель" : "дней")}
						Type="number"
						Parse="number"
						Min={1}
						Required={true}
						Value={this.props.Task.Period}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
					<DateInput
						Id="StartDate"
						Title="Начиная с"
						Value={this.props.Task.StartDate}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
				</div>
				{isWeekly &&
					<CheckGroup
						Id="PeriodDescriptionW"
						Title="Дни повторения"
						Type="checkbox"
						RowClass="week"
						List={checkListState.DaysList}
						Value={this.props.Task.PeriodDescriptionW}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
				}
				{isMonthly &&
					<TextInput
						Id="PeriodDescriptionM"
						Title="Числа месяца для повторения"
						Required={true}
						Value={this.props.Task.PeriodDescriptionM}
						onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
					/>
				}
				<PeriodTime
					Id="Time"
					Type="exact"
					Value={{ StartTime : this.props.Task.StartTime, EndTime : this.props.Task.EndTime }}
					onChange={(value,id) => checkListState.List.collect(id,value,this.props.GroupIndex, this.props.Index)}
				/>
			</div> 
		)
	}
}
TaskPeriodSetting = observer(TaskPeriodSetting);

class TaskItemRead extends Component {
	render() {
		var item = this.props.Task,
			typeList = { d : " дня", w : " недели", m : " месяца" },
			week = item.Type === "w" ? item.Description.split(",") : [],
			weekDays = _.map(week, function(v) {
				return daysShort[v]
			});

		return(
			<div className="task">
				<h4>{item.Name}</h4>
				<p>Задача повторяется каждые {item.Period} {typeList[item.Type || "d"]} c {formatDate(item.StartDate)}</p>
				{item.Type === "m" && <p>По {item.Description} дням месяца</p>}
				{item.Type === "w" && <p>По {weekDays.join(", ")}</p>}
				<p><b>Время:</b> {item.StartTime} {item.EndTime ? " – " + item.EndTime : ""}</p>
			</div>
		)
	}
}
TaskItemRead = observer(TaskItemRead);

/**
 ** СТРАНИЦА ВЫПОЛНЕНИЯ ЧЕК ЛИСТОВ
 * На данный странице пользователю отображаются задачи, которые необходимо выполнить в течение дня.
 * Список задач формируется при открытии смены с учетом времени работы филиала, должности и настроек чек листов
 * 
 * Вверху страницы отображается прогресс бар выполнения задач пользователем.
 * Пользователь может выполнить задачу только в рамках рабочего времени или во время, в которое она назначена
 * 
 ** /api/Checklists – метод, возвращающий список чек листов, для возможности генерации дополнительных чек листов
 ** /api/TasksList – метод, возвращающий список задач
 ** /api/CreateTasksList – метод, активирующий дополнительный чек лист
 ** /api/DoneTask – метод, отмечающий конкретную задачу как выполненную
 * 
 // TODO что еще хотелось бы отрефакторить на данной странице
 * Описание задачи сделать в одну строку и добавить возможность посмотреть целиком
 */
export class CheckListPage extends Component {
	constructor(props) {
		super(props);

		this.getData();
		this.getLists();
	}

	/** API запрос «Checklists» для получения чек листов */
	getLists () {
		getRequest("Checklists", {}, (data) => {
			checkListTaskState.CheckLists = data.Success && !_.isNull(data.Checklists) ? data.Checklists : [];
		});
	}

	/** API запрос «TasksList» на получение списка задач к выполнению */
	getData() { getRequest("TasksList", {}, (data) => { checkListTaskState.setTasks(data); }); }

	render() {
		var device = uistate.IsMobile ? "mobile" : uistate.IsTablet ? "tablet" : "";

		return(
			<div className={"page " + device} id="CheckListPage">
				{!authUserState.Shift.Active && <h4>{errors.SHIFT_CLOSE_TASKS}</h4> }
				{authUserState.Shift.Active &&
					<StatBlock />
				}
				{authUserState.Shift.Active && !_.isEmpty(checkListTaskState.TasksList) &&
					<TasksList getData={this.getData.bind(this)} />
				}
				{authUserState.Shift.Active && checkListTaskState.ActivateList &&
					<AddLists  getData={this.getData.bind(this)} />
				}
			</div>
		)
	}
}
CheckListPage = observer(CheckListPage);

class StatBlock extends Component {
	render () {
		var stat = checkListTaskState.TaskStat;
		
		return(
			<div className='taskStat'>
				<div>
					<h2>Задачи на сегодня</h2>
					{!_.isEmpty(checkListTaskState.ActivationLists) &&
						<button onClick={() => checkListTaskState.ActivateList = true }>Добавить чек лист</button>
					}
				</div>
				{!_.isEmpty(stat) &&
					<div>
						<div className='stat'>
							{stat.Done > 0 &&
								<div className='done' style={{ width: stat.DonePercent*100 + "%"}}>
									<span>{stat.Done}</span>
								</div>
							}
							{stat.Expired > 0 &&
								<div className='expired' style={{ width: stat.ExpiredPercent*100 + "%"}}>
									<span>{stat.Expired}</span>
								</div>
							}
							<span>{stat.Total}</span>
						</div>
						<div className='filter form'>
							<CheckFilter
								Id="ShowGroup"
								Title="Группировать по разделам"
								Value={checkListTaskState.ShowGroup}
								onChange={(value,id) => checkListTaskState.collectFilter(id, value)}
							/>
							<CheckFilter
								Id="ShowDoned"
								Title="Показать выполненные"
								Value={checkListTaskState.ShowDoned}
								onChange={(value,id) => checkListTaskState.collectFilter(id, value)}
							/>
						</div>
					</div>
				}
			</div>
		)
	}
}
StatBlock = observer(StatBlock);

class TasksList extends Component {
	render() {
		var view = this,
			listName = "",
			showList = false,
			groupName = "",
			showGroup = false;

		return(
			<div className='tasksList'>
				{_.map(checkListTaskState.TasksList, function (item, k) {
					if (checkListTaskState.ShowGroup && item.ListName !== listName){
						listName = item.ListName
						showList = true;
					} else showList = false;
					if (checkListTaskState.ShowGroup && item.Group !== groupName){
						groupName = item.Group
						showGroup = true;
					} else showGroup = false;
					
					return(<TaskExecusionItem key={k} Task={item} ShowList={showList}
						ShowGroup={showGroup} getData={view.props.getData} />)
				})}
			</div>
		)
	}
}
TasksList = observer(TasksList);

class AddLists extends Component {
	/** 
	 * API запрос «CreateTasksList» на активацию дополнительного чек-листа
	 * @param {number} id ID чек листа на активацию
	 * */
	activateList (id) {
		getRequest("CreateTasksList", { Id : id }, () => { this.props.getData(); } );
		checkListTaskState.ActivateList = false;
	}
	
	/** Закрытие модального окна */
	onCancel (event) {
		event.preventDefault();
		checkListTaskState.ActivateList = false;
	}

	render () {
		var view = this;

		return(
			<div className="back">
				<div className={"confirmBlock clearfix window " + (uistate.IsMobile ? "mobile" : "")}>
					<h4>Выберите нужный чек лист</h4>
					<div className='checklists'>
					{_.map(checkListTaskState.ActivationLists, function(item,i) {
						return(<button onClick={(id) => view.activateList(item.Id) } key={i}>{item.Title}</button>)
					})}
					</div>
					<a href="/" role="button" onClick={this.onCancel.bind(this)} className="button gray">Отменить</a>
				</div>
			</div>
		)
	}
}
class TaskExecusionItem extends Component {
	/** API запрос «DoneTask» на выполнение задачи */
	doneTask (event) {
		event.preventDefault();

		getRequest("DoneTask", { Id : this.props.Task.Id }, (data) => {
			if (data.Success) this.props.getData();
			else errorState.setError(data.ErrorCode);
		});
	}

	render () {
		var task = this.props.Task;

		return(
			<>	
				{this.props.ShowList &&
					<h4 className='title'>{task.ListName}</h4>
				}
				{this.props.ShowGroup && <h5 className='title'>{task.Group}</h5>}
				<div className={"name " + task.ClassName}>
					<span>{task.Name}</span>
					{task.Description &&
						<p className='comment'>{task.Description}</p>
					}
					{uistate.IsMobile && <p className="comment">{task.Time}</p>}
				</div>
				{!uistate.IsMobile &&
					<div className="time">{task.Time}</div>
				}
				<button className={"button " + task.Button.Color + (uistate.IsMobile ? " icon activate" : "")}
					disabled={task.Button.Disabled} 
					onClick={this.doneTask.bind(this)}>
						{uistate.IsMobile && <CheckIcon Fill="#FFFFFF" />}
						{!uistate.IsMobile && task.Button.Title}
					</button>
			</>
		)
	}
}

/**
 ** ОТЧЕТ ПО ВЫПОЛНЕНИЮ ЧЕК ЛИСТОВ
 * В отчетах отображается сводка по выполнению задач чек листов
 * В верхнем блоке присутствует общая статистика задач - выполнено/не выполнено
 * Далее отображаются группы задача с соответствующей информацией по выполнению. 
 * Группа формируется на основании ДАТА+ЧЕКЛИСТ+ПОЛЬЗОВАТЕЛЬ+ДОЛЖНОСТЬ+ФИЛИАЛ. 
 * Невыполненные общие задачи попадают в каждую группу
 * 
 * Каждую группу можно развернуть и получить конкретный списко задач, поставленный пользователю
 * 
 ** /api/Users – метод, отдающий список пользователей для фильтра
 ** /api/Filials – метод, отдающий список филиалов для фильтра
 ** /api/Roles – метод, отдающий список должностей для фильтра
 ** /api/Checklists – метод, отдающий список чек листов для фильтра
 *
 ** /api/ChecklistReport – метод, отдающий отчет по чек листам в виде групп ДАТА+ЧЕКЛИСТ+ПОЛЬЗОВАТЕЛЬ+ДОЛЖНОСТЬ+ФИЛИАЛ
 ** /api/ChecklistStats – метод, возвращающий сводную статистику по задачам
 ** /api/GetGroupTasks – метод получения задач группы
 */

export class CheckListReport extends Component {
	constructor(props) {
		super(props);

		checkListReportState.setDefault();

		this.state = { isOpen : false }

		this.getData();
		this.getStat();

		getRequest("Users", { Active : true }, function(data) { checkListReportState.setUsers(data); });
		getRequest("Filials", {}, function(data) { checkListReportState.Filials = data.Success ? data.Filials : []; });
		getRequest("Roles", {}, function(data) { checkListReportState.Roles = data.Success ? data.Roles : []; });
		getRequest("Checklists", {}, function(data) { checkListReportState.CheckLists = data.Success ? data.Checklists : []; });
	}

	/** API запрос «ChecklistReport» и «ChecklistStats» для получения списка выполненных и поставленных задач */
	getData () {
		checkListReportState.IsSendRequest = false;
		getRequest("ChecklistReport", checkListReportState.getData(), (data) => {
			checkListReportState.setGroups(data);
		});
		if (checkListReportState.Filter.Offset === 0)
			getRequest("ChecklistStats", checkListReportState.getData(), (data) => {
				checkListReportState.setStat(data);
			});
	}

	/** API запросы для получения рейтингов
	 * CheckListRateUsers — получение рейтинга пользователей
	 * CheckListRateLists – получение рейтинга чек листов
	 * CheckListRateTasks — получение рейтинга задач
	 */
	getStat () {
		getRequest("CheckListRateUsers", checkListReportState.RateFilter, (data) => {
			checkListReportState.UsersRate = data.Success && !_.isUndefined(data.Users) && !_.isEmpty(data.Users) ?
				data.Users : [];
		});

		getRequest("CheckListRateLists", checkListReportState.RateFilter, (data) => {
			checkListReportState.ListsRate = data.Success && !_.isUndefined(data.CheckLists) && !_.isEmpty(data.CheckLists) ?
				data.CheckLists : [];
		});

		getRequest("CheckListRateTasks", checkListReportState.RateFilter, (data) => {
			checkListReportState.TasksRate = data.Success && !_.isUndefined(data.Tasks) && !_.isEmpty(data.Tasks) ?
				data.Tasks : [];
		});
	}

	/** Показать/скрыть блок с полями поиска */
	toggleFilter (event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });
	}

	/** Отобразить другую страницу результатов */
	changePage (offset) { 
		checkListReportState.Filter.Offset = offset;
		this.getData();
	}

	render() {
		return(
			<div id="CheckListReport" className={"page " + (uistate.IsMobile ? " mobile" : "")} >
				<div className="head">
					<div>
						<h2>Отчеты по чек-листам</h2>
						{!uistate.isDevice &&
							<a href="/" className={"iconSvg filter " + (this.state.isOpen ? "active" : "")} 
								title="Фильтр поиска" onClick={this.toggleFilter.bind(this)}><FilterIcon/></a>
						}
					</div>
					{this.state.isOpen && 
						<CheckListReportFilter getData={this.getData.bind(this)} />
					}
				</div>
				{checkListReportState.IsSendRequest && _.isEmpty(checkListReportState.Groups) &&
					<p>{errors.EMPTY_RESPONSE}</p>
				}
				{!_.isEmpty(checkListReportState.Groups) &&
					<>
						<StatReportBlock />
						<CheckListReportGroups />
					</>
				}
				<TablePagination
					Limit={checkListReportState.Limit}
					HasMore={checkListReportState.HasMore}
					Offset={checkListReportState.Filter.Offset}
					changePage={this.changePage.bind(this)}
				/>
				{!_.isUndefined(checkListReportState.FullRate) &&
					<CheckListReportRateBlock />
				}
			</div>
		)
	}
}
CheckListReport = observer(CheckListReport);

class CheckListReportFilter extends Component {
	/** Отправка запроса фильтра по чек листам */
	handleSubmit (event) {
		event.preventDefault();
		checkListReportState.Filter.Offset = 0;
		this.props.getData();
	}

	render() {
		return(
			<form onSubmit={this.handleSubmit.bind(this)} className="filterBlock">
				<div>
					<DatePreset
						DateFrom={checkListReportState.Filter.DateFrom}
						DateTo={checkListReportState.Filter.DateTo}
						IsClearable={false}
						onChange={(value,id) => checkListReportState.collectFilter(id,value)}
					/>
					<div className='lists'>
						<Selectize 
							Id="UserId"
							Title="Пользователь"
							List={toSelectList(checkListReportState.Users)}
							Value={checkListReportState.Filter.UserId}
							onChange={(value,id) => checkListReportState.collectFilter(id,value)}
						/>
						<Selectize 
							Id="RoleId"
							Title="Должность"
							List={toSelectList(checkListReportState.Roles)}
							Value={checkListReportState.Filter.RoleId}
							onChange={(value,id) => checkListReportState.collectFilter(id,value)}
						/>
						<Selectize 
							Id="FilialId"
							Title="Филиал"
							List={toSelectList(checkListReportState.Filials)}
							Value={checkListReportState.Filter.FilialId}
							onChange={(value,id) => checkListReportState.collectFilter(id,value)}
						/>
						<Selectize 
							Id="Id"
							Title="Чек-лист"
							List={toSelectList(checkListReportState.CheckLists)}
							Value={checkListReportState.Filter.Id}
							onChange={(value,id) => checkListReportState.collectFilter(id,value)}
						/>
					</div>
				</div>
				<button>Найти</button>
			</form>
		)
	}
}
CheckListReportFilter = observer(CheckListReportFilter);

class StatReportBlock extends Component {
	openStat (event) {
		event.preventDefault();
		checkListReportState.openRate(event.currentTarget.dataset.rate)
	}

	render() {
		var stat = checkListReportState.TasksStat,
			firstUser = checkListReportState.UsersRate.length > 0 ? checkListReportState.UsersRate[0] : undefined,
			lastUser = checkListReportState.UsersRate.length > 1 ? _.last(checkListReportState.UsersRate) : undefined,
			lastList = checkListReportState.ListsRate.length > 0 ? _.last(checkListReportState.ListsRate) : undefined,
			lastTask = checkListReportState.TasksRate.length > 0 ? _.last(checkListReportState.TasksRate) : undefined;
		
		return(
			<div className='statBlock'>
				<div className='statistic'>
					<div className='done'>
						<span className='icon'><StatDoneIcon /></span>
						<div>
							<span className='title'>Выполнено вовремя</span>
							<span className='count'>{stat.DoneTasks}/{stat.TotalTasks}</span>
						</div>
					</div>
					<div className='expired'>
						<span className='icon'><StatExpiredIcon/></span>
						<div>
							<span className='title'>Просрочено</span>
							<span className='count'>{stat.ExpiredTasks}/{stat.TotalTasks}</span>
						</div>
					</div>
					<div className='failed'>
						<span className='icon'><StatFailedIcon/></span>
						<div>
							<span className='title'>Не выполнено</span>
							<span className='count'>{stat.FailedTasks}/{stat.TotalTasks}</span>
						</div>
					</div>
				</div>
				<div className='statistic rate'>
						<CheckListReportUser
							User={firstUser}
							Type="bestEmployee"
							ShowRate={true}
							openStat={this.openStat.bind(this)}
						/>
						<CheckListReportUser
							User={lastUser}
							Type="worthEmployee"
							ShowRate={true}
							openStat={this.openStat.bind(this)}
						/>
						<div className='worthList'>
							<span className='icon'><StatListIcon/></span>
							<div>
								<span className='title'>Самый сложный чек-лист</span>
								{!_.isUndefined(lastList) && <span>{lastList.Name}</span>}
							</div>
							<a href="/" className='iconSvg' title='Посмотреть полный список' data-rate="list" onClick={this.openStat.bind(this)}><StatIcon /></a>
						</div>
						<div className='worthTask'>
							<span className='icon'><StatTaskIcon/></span>
							<div>
								<span className='title'>Самая сложная задача</span>
								{!_.isUndefined(lastTask) && <span>{lastTask.Name}</span>}
							</div>
							<a href="/" className='iconSvg' title='Посмотреть полный список' data-rate="task" onClick={this.openStat.bind(this)}><StatIcon /></a>
						</div>
				</div>
			</div>
		)
	}
}
StatReportBlock = observer(StatReportBlock);

class CheckListReportUser extends Component {
	render() {
		var best = this.props.Type === "bestEmployee";
		return(
			<div className={this.props.Type}>
				{(_.isUndefined(this.props.User) || this.props.User.PhotoURL === "") &&
					<span className='icon'>
						{best ? <StatBestIcon/> : <StatLikeIcon/>}
					</span>
				}
				{!_.isUndefined(this.props.User) && this.props.User.PhotoURL !== "" &&
					<span className='icon photo' style={{ backgroundImage : "url('" + this.props.User.PhotoURL + "')"}}></span>
				}
				<div>
					<span className='title'>{best ? "Лучший за месяц" : "Худший за месяц"}</span>
					{!_.isUndefined(this.props.User) &&
						<>
							<span>{this.props.User.FirstName} {this.props.User.LastName}</span>
							<span className='count'>{this.props.User.Done + this.props.User.Expired}/{this.props.User.Total}</span>
						</>
					}
				</div>
				{this.props.ShowRate &&
					<a href="/" className='iconSvg' title='Посмотреть полный список' data-rate="user" onClick={this.props.openStat}><StatIcon /></a>
				}
			</div>
		)
	}
}
CheckListReportUser = observer(CheckListReportUser)

class CheckListReportGroups extends Component {
	render() {
		return(
			<div className='groups'>
				<>
					<h5>Дата</h5>
					<h5>Пользователь</h5>
					<h5>Чек лист</h5>
					{!uistate.isDevice &&
						<>
							<h5>Должность</h5>
							<h5>Филиал</h5>
						</>
					}
					<h5>&nbsp;</h5>
					{!uistate.isDevice &&
						<h5>&nbsp;</h5>
					}
				</>
				{_.map(checkListReportState.Groups, function(item, i) {
					return(<CheckListReportGroupsItem key={i} Item={item}/>)
				})}
			</div>
		)
	}
}
CheckListReportGroups = observer(CheckListReportGroups);

class CheckListReportGroupsItem extends Component {
	constructor(props) {
		super(props);

		this.state = { 
			isOpen : false,
		}
	}

	/** API метод «GetGroupTasks» получения задач в группе */
	getTasks(event) {
		event.preventDefault();
		this.setState({ isOpen : !this.state.isOpen });

		if (!this.state.isOpen && (_.isUndefined(this.props.Item.Tasks) || _.isEmpty(this.props.Item.Tasks)))
			getRequest("GetGroupTasks", { GroupName : this.props.Item.GroupName }, (data) => {
				checkListReportState.setTasks(data, this.props.Item.GroupName)
			})
	}

	render() {
		return(
			<>
				<span className='date'>{formatDate(this.props.Item.ShiftDate)}</span>
				<span>
					{this.props.Item.UserName}
					{uistate.isDevice &&
						<>
							<p className='comment'>{this.props.Item.RoleName}</p>
							<p className='comment'>{this.props.Item.FilialName}</p>
						</>
					}
				</span>
				<span>{this.props.Item.CheckListName}</span>
				{!uistate.isDevice &&
					<>
						<span>{this.props.Item.RoleName}</span>
						<span>{this.props.Item.FilialName}</span>
					</>
				}
				<span>
					<div className='stat'>
						{this.props.Item.DoneTasks > 0 &&
							<div className='done' style={{ width: this.props.Item.DonePercent*100 + "%"}}>
								<span>{this.props.Item.DoneTasks}</span>
							</div>
						}
						{this.props.Item.ExpiredTasks > 0 &&
							<div className='expired' style={{ width: this.props.Item.ExpiredPercent*100 + "%"}}>
								<span>{this.props.Item.ExpiredTasks}</span>
							</div>
						}
						{this.props.Item.FailedTasks > 0 &&
							<span>{this.props.Item.FailedTasks}</span>
						}
					</div>
				</span>
				{!uistate.isDevice &&
					<span className='actions'>
						<a className={'iconSvg ' + (this.state.isOpen ? "active" : "")} href='/'
							onClick={this.getTasks.bind(this)}><ArrowIcon /></a>
					</span>
				}
				{this.state.isOpen && !_.isUndefined(this.props.Item.Tasks) && !_.isEmpty(this.props.Item.Tasks) &&
					<div className='tasks'>
						<>
							<h5>Задача</h5>
							<h5>Индивидуальная</h5>
							<h5>Обязательная</h5>
							<h5>Расписание</h5>
							<h5>Выполнена</h5>
						</>
						{_.map(this.props.Item.Tasks, function(item, i) {
							return(<CheckListReportTaskItem key={i} Item={item}/>)
						})}
					</div>
				}
			</>
		)
	}
}
CheckListReportGroupsItem = observer(CheckListReportGroupsItem);

class CheckListReportTaskItem extends Component {
	render() {
		return(
			<>
				<span>{this.props.Item.Name}</span>
				<span className='icon'>{this.props.Item.Individual && <CheckIcon />}</span>
				<span className='icon'>{this.props.Item.Required && <CheckIcon />}</span>
				<span>{this.props.Item.Schedule}</span>
				<span className='done'>
					{this.props.Item.InTime && this.props.Item.DoneTime !== "" && <i className='intime'><CheckIcon /></i>}
					{this.props.Item.Expired && <i className='expired'><StatExpiredIcon /></i>}
					{formatDate(this.props.Item.DoneTime, "time")}
				</span>
			</>
		)
	}
}
CheckListReportTaskItem = observer(CheckListReportTaskItem);

class CheckListReportRateBlock extends Component {
	/** Заркытие окна с акциями */
	handleClose(event) {
		event.preventDefault()
		checkListReportState.openRate();
	}

	/** Закрытие окна с акциями по клику вне блока */
	handleBackClose(event) {
		if (event.target.getAttribute("rel") === "back") checkListReportState.openRate();
	}

	render() {
		var style = uistate.windowLargeDimensions;

		return (
			<div className="back" onClick={this.handleBackClose.bind(this)} rel="back">
				<div className="clearfix content window large" style={style}>
					<a href="/" className="close" onClick={this.handleClose.bind(this)}><i></i></a>
					<div className="clearfix">
						{checkListReportState.FullRate.Type === "user" &&
							<h4>Рейтинг пользователей по выполенным задачам</h4>
						}
						{checkListReportState.FullRate.Type === "list" &&
							<h4>Рейтинг выполняемости чек-листов</h4>
						}
						{checkListReportState.FullRate.Type === "task" &&
							<h4>Рейтинг выполняемости задач</h4>
						}
						<div className='stat'>
							<h5>№</h5>
							{checkListReportState.FullRate.Type === "user" && <h5>Пользователь</h5>}
							{checkListReportState.FullRate.Type !== "user" && <h5>Название</h5>}
							<h5>Поставлено задач</h5>
							<h5>Выполнено вовремя</h5>
							<h5>Просрочено</h5>
							<h5>% выполнения</h5>
							{_.map(checkListReportState.FullRate.List, (v,i) => {
								return(<CheckListReportRateItem Item={v} key={i} />)
							})}
						</div>
					</div>
				</div>
			</div>
		)
	}
}
CheckListReportRateBlock = observer(CheckListReportRateBlock);

class CheckListReportRateItem extends Component {
	render() {
		var percent = ((this.props.Item.Expired + this.props.Item.Done)/this.props.Item.Total)*100;
		return(
			<>
				<span>{this.props.Item.Rate}</span>
				{checkListReportState.FullRate.Type === "user" &&
					<span>{this.props.Item.FirstName} {this.props.Item.LastName}</span>
				}
				{checkListReportState.FullRate.Type !== "user" &&
					<span>
						{this.props.Item.Name}
						{checkListReportState.FullRate.Type === "task" &&
							<span className='comment'>{this.props.Item.CheckList}</span>
						}
					</span>
				}
				<span>{this.props.Item.Total}</span>
				<span>{this.props.Item.Done}</span>
				<span>{this.props.Item.Expired}</span>
				<span>{percent.toFixed(1)}%</span>
				
			</>
		)
	}
}
CheckListReportRateItem = observer(CheckListReportRateItem);

/**
 * ВИДЖЕТ ВЫПОЛНЕНИЯ ЧЕК ЛИСТОВ
 * Виджеты располагаются на дашборде. Данный виджет отображает ближайшие задачи на выполнение.
 * Список задач формируется при открытии смены с учетом времени работы филиала, должности и настроек чек листов
 * 
 * Вверху Виджета отображается прогресс бар выполнения задач пользователем.
 * Пользователь может выполнить задачу только в рамках рабочего времени или во время, в которое она назначена
 * 
 ** /api/TasksList – метод, возвращающий список задач
 ** /api/DoneTask – метод, отмечающий конкретную задачу как выполненную
 */
export class TaskListWidget extends Component {
	disposer = autorun(() => {
		if (authUserState.Shift.Active) this.getData();
	});

	/** API запрос «TasksList» для получения списка чек листов */
	getData() {
		getTasks((data) => { 
			taskListWidgetState.Tasks = data.Success && !_.isNull(data.Tasks) ? data.Tasks : [];
		});
	}

	render() {
		return(
			<div className="widget" id="TaskListWidget">
				<h4>Мои задачи на сегодня</h4>
				<NavLink to="/CheckList" className="openPage" title="Перейти на страницу чек-листы"><i></i></NavLink>
				{!authUserState.Shift.Active &&
					<p className="comment">{errors.SHIFT_CLOSE_TASKS}</p>
				}
				{authUserState.Shift.Active && _.isEmpty(taskListWidgetState.Tasks) &&
					<p className="comment">{errors.EMPTY_TASKS}</p>
				}
				{authUserState.Shift.Active && !_.isEmpty(taskListWidgetState.Tasks) &&
					<TaskWidgetStat />
				}
				{!_.isEmpty(taskListWidgetState.TasksList) && authUserState.Shift.Active &&
					<div className='list'>
						{_.map(taskListWidgetState.TasksList, (task, i) => {
							return(<TaskItemWidget key={i} Task={task} getData={this.getData.bind(this)}/>)
						})}
					</div>
				}
			</div>
		)
	}
}
TaskListWidget = observer(TaskListWidget);

class TaskWidgetStat extends Component {
	render() {
		return(
			<div className='stat'>
				<div className='done' style={{ width: taskListWidgetState.TaskStat.DonePercent*100 + "%"}}></div>
				<div className='expired' style={{ width: taskListWidgetState.TaskStat.ExpiredPercent*100 + "%"}}></div>
			</div>
		)
	}
}
TaskWidgetStat = observer(TaskWidgetStat)

class TaskItemWidget extends Component {
	/** API запрос «DoneTask» на выполнение задачи */
	doneTask () { doneTaskbyId(this.props.Task.Id, () => { this.props.getData(); }); }

	render () {
		return(
			<>
				<span className='name'>{this.props.Task.Name}</span>
				<button className={"button " + this.props.Task.Button.Color}
					disabled={this.props.Task.Button.Disabled} onClick={this.doneTask.bind(this)}><CheckIcon Fill="#FFFFFF" /></button>
			</>
		)
	}
}

/**
 * ВИДЖЕТ НАСТРОЙКИ ЧЕК ЛИСТОВ
 * Виджеты располагаются на дашборде. Данный виджет отображает быстрые кнопки для настройки чек листов.
// TODO что еще хотелось бы отрефакторить на данной странице
 * Интегрировать заявку на настройку
 */
export class CheckListWidget extends Component {
	constructor(props) {
		super(props);

		getAccount({}, (data) => {
			if (data.Success) checkListWidgetState.MessageParams.UserPhone = data.Login;
		});
	}

	/** Окно подтверждения заявки на настройку */
	handleClick() {
		var title = "Помочь с настройкой?",
			text = "Мы предлагаем бесплатную услугу по настройке системы под ваши задачи. Оставьте заявку и мы свяжемся с вами в ближайшее время чтобы уточнить детали.";
		
		confirmState.openConfirm(title, () => {
			sendMessageRequest(checkListWidgetState.MessageParams, (data) => {
				if (data.contact) errorState.setError(undefined, undefined, "Ваша заявка отправлена, мы скоро с вами свяжемся", "success");
				else errorState.setError(undefined, undefined, "Что то пошло не так. Попробуйте еще раз или напишите на support@ectrum.ru");
			})
		}, text)
	}

	render() {
		return(
			<div className="widget" id="CheckListWidget">
				<h4>Настройка чек листов</h4>
				<NavLink to="/CheckLists?new" className="button">Создать чек лист</NavLink>
				<NavLink to="/CheckLists" className="button">Использовать шаблон</NavLink>
				<button className='green' onClick={this.handleClick.bind(this)}>Заказать настройку</button>
			</div>
		)
	}
}
CheckListWidget = observer(CheckListWidget);

export class CheckListReportWidget extends Component {
	constructor(props) {
		super(props);

		getRequest("ChecklistStats", checkListReportWidgetState.Filter, (data) => {
			checkListReportWidgetState.setStat(data);
		});

		getRequest("CheckListRateUsers", checkListReportWidgetState.RateFilter, (data) => {
			checkListReportWidgetState.setUsers(data);
		});
	}

	render() {
		return(
			<div className="widget" id="CheckListReportWidget">
				<h4>Выполнение чек листов</h4>
				<NavLink to="/CheckListReports" className="openPage" title="Перейти в отчеты по чек листам"><i></i></NavLink>
				{!_.isEmpty(checkListReportWidgetState.Stat) &&
					<>
						<CheckListReportStatWidget />
						<CheckListReportUserWidget />
					</>
				}
				{_.isEmpty(checkListReportWidgetState.Stat) &&
					<>
						<img src={EmptyCheckListImage} alt="Нет данных для рейтинга" />
						<p>Настройте чек листы, чтобы появился рейтинг задач</p>
					</>

				}
			</div>
		)
	}
}
CheckListReportWidget = observer(CheckListReportWidget);

class CheckListReportStatWidget extends Component {
	render() {
		return(
			<div className='tasksStat'>
				<div className='pie' style={{background : checkListReportWidgetState.Stat.Gradient}}></div>
				<a href='/' className='svgIcon'><InfoIcon /></a>
				<div className='legend'>
					<p>Выполнено <b>{checkListReportWidgetState.Stat.Done}</b> из {checkListReportWidgetState.Stat.Total}</p>
					<p>Просрочено <b>{checkListReportWidgetState.Stat.Expired}</b> из {checkListReportWidgetState.Stat.Total}</p>
					<p>Не выполнено <b>{checkListReportWidgetState.Stat.Failed}</b> из {checkListReportWidgetState.Stat.Total}</p>
				</div>
			</div>
		)
	}
}
CheckListReportStatWidget = observer(CheckListReportStatWidget);
class CheckListReportUserWidget extends Component {
	render() {
		return(
			<div className='userStat'>
				<CheckListReportUser
					User={_.head(checkListReportWidgetState.Users)}
					Type="bestEmployee"
				/>
				<CheckListReportUser
					User={checkListReportWidgetState.Users[1]}
					Type="worthEmployee"
				/>
			</div>
		)
	}
}
CheckListReportUserWidget = observer(CheckListReportUserWidget);