import { observable, decorate, computed } from 'mobx';
import _ from 'lodash';
import { isTablet, isAndroid, isMobile } from 'react-device-detect';
import cookie from 'react-cookies';
import { errors } from '../dictionary';
import { checkModule } from '../functions';

/** Хранилище переменных для отображения интерфейса */
class UIState {
	Language = "ru_RU";
	WindowDimensions = {
		width: window.innerWidth,
		height: window.innerHeight
	};
	
	IsSideBarOpen = !isTablet && !isAndroid;
	MenuActive = "";
	
	CommonWidth = 865;
	MaxMenuCount = 10;
	menuPosition = 0;
	HeaderHeight = 70;
	TitleHeight = 45;
	PaddingHeight = 35;
	
	IsTablet = isTablet || isAndroid;
	IsMobile = isMobile && !isTablet;

	constructor() { window.addEventListener("resize", () => { this.getWindowDimensions(); }); }

	/** Получение размеров рабочего окна */
	getWindowDimensions() { this.WindowDimensions = { width: window.innerWidth, height: window.innerHeight }; }

	/**
	 * 
	 * @param {*} type 
	 * @param {*} groupLength 
	 */
	getMenuPosition(type, groupLength) {
		var itemWidth = this.CommonWidth / this.MaxMenuCount;
		this.menuPosition = type === "next" ? this.menuWidth - groupLength * itemWidth - 10 : 0;
	}

	/**
	 * Проверка, что отображение на переносном устройстве — мобильный или планшет
	 * @returns {boolean} переносное устройство
	 */
	get isDevice () { return this.IsMobile || this.IsTablet; };

	/**
	 * Проверка, ориентации переносного устройства
	 * @returns {boolean} устройство расположено верстикально
	 */
	get isVertical () { 
		if (!this.isDevice) return false;

		return this.WindowDimensions.width < this.WindowDimensions.height;
	};
	
	/**
	 * Вычисление высоты для левого меню
	 * @return {object} набор стилей для левого меню
	 */
	get styleMenu() {
		var sItem = 0,
			sBlock = 30 + 45 + 10,
			availableMerchants = _.filter(authUserState.User.Merchants, function (v) {
				return _.filter(authUserState.User.Roles, { MerchantId : v.Id }).length > 0
			});

		if (authUserState.IsAuth) {
			if (!_.isNull(authUserState.User.Merchants) && availableMerchants.length > 1) sItem += 1;
			if (!_.isNull(authUserState.User.Roles)) sItem += 1;
			if (!_.isNull(authUserState.User.Filials)) sItem += 1;
		}

		sBlock += sItem * 37;

		var shiftSize = authUserState.Shift.Active && !authUserState.User.IsCourier ? 45 : 0;
		return { height: this.WindowDimensions.height - this.HeaderHeight - (this.IsSideBarOpen ? sBlock : shiftSize) };
	}
	/**
	 * Вычисление высоты основного окна
	 * @return {number} высота внутреннего контента страницы
	 */
	get sectionHeight() { 
		return this.WindowDimensions.height - (this.IsTablet ? -10 :this.HeaderHeight) - this.PaddingHeight - this.TitleHeight; 
	}
	/**
	 * Вычисление вычисление ширины левого меню
	 * @return {number} ширина левого меню
	 */
	get menuWidth() { return this.WindowDimensions.width - 165 - (this.IsSideBarOpen ? 260 : 50); }

	/**
	 * Вычисление отступа страницы слева
	 * @return {number} отступ страницы от левого края окна
	 */
	get pageMargin() { return this.IsSideBarOpen && !this.IsTablet ? 260 : 50 };

	get windowLargeDimensions () {
		return { 
			left : uistate.IsMobile ? "3%" : uistate.IsTablet ? "10%" : uistate.IsSideBarOpen ? "calc(260px + 5%)" : "calc(50px + 10%)",
			width : uistate.IsMobile ? "94%" : uistate.IsTablet ? "80%" : 
				uistate.IsSideBarOpen ? "calc(90% - 260px)" : "calc(80% - 50px)"
		}
	}
}
decorate(UIState, {
	Language: observable,
	WindowDimensions: observable,
	IsSideBarOpen: observable,
	MenuActive: observable,

	styleMenu: computed,
	sectionHeight: computed,
	menuWidth: computed,
	menuPosition: observable
});
export const uistate = new UIState();

/** Хранилище переменных для авторизированного пользователя */
class AuthUserState {
	User = null;
	SessionEnd = false;
	
	Units = [];
	DashboardId = -1;

	LoginUser = { Login : "", Password : "" };
	RecoverUser = { Login : "" };
	RegisterUser = {
		MerchantName : "",
		UserFirstName : "",
		UserPhone : "",
		UserEmail : "",
		FilialName : "",
		FilialAddress : "",
		CityId : -1
	}
	RegisterStep = "step1";
	ButtonLoading = false;
	LoginError = "";
	RetriesLeft = 0;
	AllCities = [];

	IsAuth = false;
	IsCheckAuth = false;
	IsRecoverPass = false;

	/**
	 * Функция, разбирающая ответ от сервера с данными авторизированного пользователя
	 * @param {object} user 
	 */
	setAuthUser(user) {
		this.Merchant = _.isUndefined(user) ? null : user.CurrentMerchant;
		this.User = _.isUndefined(user) ? null : _.omit(user, ["Success", "ErrorCode"]);

		this.Shift = {
			Active: !_.isUndefined(user) && user.Shift.Active,
			Date: !_.isUndefined(user) ? user.Shift.Date : "",
			RoleId: !_.isUndefined(user) && user.Shift.Active ? user.Shift.RoleId : null,
			FilialId: !_.isUndefined(user) && user.Shift.Active ? user.Shift.FilialId : null
		};

		this.CassaShifts = !_.isUndefined(user) ? user.CassaShifts : [];

		uistate.IsSideBarOpen = _.isUndefined(user) || !user.Shift.Active;
		uistate.MenuActive = uistate.IsTablet && !_.isUndefined(user) && !user.Shift.Active ? "Shift" : "";

		this.Role = null;
		this.Filial = null;
		this.Stocks = null;
		
		if (!_.isUndefined(user)) {
			var roleId = cookie.load("RoleId"),
				filialId = cookie.load("FilialId"),
				role = !_.isUndefined(roleId) ? _.find(user.Roles, { Id: parseInt(roleId, 10) }) : undefined,
				filial = !_.isUndefined(filialId) ? _.find(user.Filials, { Id: parseInt(filialId, 10) }) : undefined,
				shiftRole = _.find(user.Roles, { Id: user.Shift.RoleId });
			
			this.Role = !_.isUndefined(role) ? role
				: user.IsAdmin && _.isNull(user.Roles) ? { Id: 0 }
				: user.Shift.Active && shiftRole.MerchantId === user.CurrentMerchant ? shiftRole
				: _.filter(user.Roles, { MerchantId : user.CurrentMerchant })[0];
			this.Filial = _.isNull(user.Filials) ? null : !_.isUndefined(filial) ? filial
				: !_.isUndefined(user) ? user.Shift.Active ? _.find(user.Filials, { Id: user.Shift.FilialId })
					: _.filter(user.Filials, { MerchantId : user.CurrentMerchant })[0] : null;
			// TODO перенести в computed
			this.Stocks = _.isUndefined(user) || _.isNull(user.Filials) ? []
				: user.Shift.Active ? _.find(user.Filials, { Id: user.Shift.FilialId }).Stocks
				: user.Filials[0].Stocks;

			if (!_.isUndefined(this.Role)) cookie.save("RoleId", this.Role.Id);
			if (!_.isUndefined(this.Filial)) cookie.save("FilialId", this.Filial.Id);
			if (!_.isUndefined(this.Merchant)) cookie.save("MerchantId", this.Merchant);
		}

		this.City = _.isNull(this.Filial) || _.isUndefined(this.Filial) ? null : this.Filial.CityId;

		this.IsCheckAuth = true;
		this.IsAuth = !_.isUndefined(user);
		this.IsRecoverPass = false;

		if (!_.isUndefined(user)) this.resetLogin();
	}

	setUnits(units) { this.Units = units; }

	get CurrentMerchant () {
		return _.isUndefined(this.User) || _.isUndefined(this.Merchant) ? undefined :
			_.find(this.User.Merchants, { Id : this.Merchant });
	}

	/** Получение типа предприятия */
	get MerchantType () {
		return _.isUndefined(this.CurrentMerchant) ? null : this.CurrentMerchant.MerchantType;
		// return _.isUndefined(this.User) ? null : _.find(this.User.Merchants, { Id : this.Merchant })
		// 	? _.find(this.User.Merchants, { Id : this.Merchant }).MerchantType : null;
	}

	/** Флаг, что пользователь является получателем чаевых */
	get TipsRecipient () { return this.IsAuth ? this.User.TipsRecipient : false; }

	/**
	 * Функция вычисляющая набор рабочих сталов для пользователя в конкретной должности
	 * @return {object} Dashboards набор рабочих столов
	 */
	get Dashboards () {
		if (!this.IsAuth || _.isNull(this.User) || _.isUndefined(this.Role) || _.isNull(this.Role)) 
			return { Menu : [], Sidebar : [], Widget : [] };

		// console.log(this.CurrentMerchant)

		var dashboards = _.find(this.User.Dashboards, { RoleId: this.Role.Id });

		if (_.isUndefined(dashboards) || _.isEmpty(dashboards))
			return { Menu : [], Sidebar : [], Widget : [] };

		if (!this.CurrentMerchant.Active) {
			return {
				Menu: _.filter(dashboards.Dashboards, { SubGroup : "Common" }),
				Sidebar: [],
				Widget: []
			}
		}

		return {
			Menu: _.filter(_.sortBy(dashboards.Dashboards, ["Id"]), { Type: "Menu" }),
			Sidebar: _.filter(_.sortBy(dashboards.Dashboards, ["Id"]), { Type: "Sidebar" }),
			Widget: _.filter(_.sortBy(dashboards.Dashboards, ["Id"]), { Type: "Widget" })
		}
	}

	/**
	 * Получение списка модулей активного предприятия
	 * @returns {[string]} Названия модулей
	 */
	get Modules () {
		if (_.isUndefined(this.User) || _.isEmpty(this.Merchants)) return [];
		var merchant = _.find(this.Merchants, { Id : this.Merchant });

		return merchant ? merchant.Modules : [];
	}

	/** ФЛаги наличия модулей у предприятия */
	get HasERP () { return checkModule("SimpleERP"); }
	get HasTips () { return checkModule("Tips"); }
	get HasCheckList () { return checkModule("SimpleCheckList"); }

	/**
	 * Получение списка доступных предприятий пользователя
	 * @return {[object]} Merchants массив предприятий
	 */
	get Merchants () { return this.IsAuth ? this.User.Merchants : []; }

	/** Флаг, что предприятие является колл-центром */
	get isCallCentre () { return this.IsAuth ? this.MerchantType === 2 : false; }
	/**
	 * Получение списка доступных должностей пользователя с учетом активного предприятия
	 * @return {[object]} Roles массив должностей
	 */
	get Roles () { 
		return this.IsAuth ? _.filter(_.clone(this.User.Roles), { MerchantId : this.Merchant }) : []; 
	}
	/**
	 * Получение списка доступных филиалов с учетом активного предприятия и города
	 * @return {[object]} Filials массив филиалов
	 */
	get Filials () {
		return this.IsAuth ? _.filter(_.clone(this.User.Filials), { MerchantId : this.Merchant, CityId : this.City }) : [];
	}
	/**
	 * Получение списка доступных филиалов для совершения заказа
	 * @return {[object]} OrderFilials массив филиалов, в которых можно сделать заказ
	 */
	get OrderFilials () {
		if (!this.IsAuth) return [];
		var merchant = this.Merchant;

		if (this.isCallCentre) 
			return _.filter(_.clone(this.User.Filials), function (v) { return v.MerchantId !== merchant; });
		else return _.filter(_.clone(this.User.Filials), { MerchantId : this.Merchant, CityId : this.City });
	}

	get TipsBrands () {
		if (!this.IsAuth) return [];

		var brands = _.map(this.Filials, function(v) { return _.map(v.POS, "Brands"); });
		
		return _.compact(_.uniqBy(_.flattenDeep(brands), "Id"));
	}
	
	/**
	 * Получение списка доступных доступных складов во всех филиалах пользователя выбранного предприятия
	 * @return {[object]} allStaocks массив складов
	 */
	get allStocks () { return !this.IsAuth ? [] : _.compact(_.flatMap(_.map(this.User.Filials, "Stocks"))); }
	/**
	 * Получения списка доступных городов пользователя с учетом выбранного предприятия
	 * @return {[object]} Cities массив город
	 */
	get Cities () {
		if (!this.IsAuth) return [];
		
		var cities = _.map(_.filter(_.clone(this.User.Filials), { MerchantId : this.Merchant }), function (v) { 
			return { Id : v.CityId, Name : v.City, Location : v.Location, TimeZone : v.TimeZone } });

		return _.uniqBy(cities, "Id")
	}

	/**
	 * Получения списка доступных городов пользователя с учетом выбранного предприятия
	 * @return {[object]} Cities массив город
	 */
	get OrderCities () {
		if (!this.IsAuth) return [];
		
		var cities = _.map(this.OrderFilials, function (v) { 
			return { Id : v.CityId, Name : v.City, Location : v.Location, TimeZone : v.TimeZone } });

		return _.uniqBy(cities, "Id")
	}

	/**
	 * Разбор ответа от сервера с проверкой возможности закрытия смены
	 * @param {object} data 
	 */
	openCheckShift (data) {
		this.CheckShift = _.isUndefined(data) ? undefined : {
			CanCloseShift : data.CanCloseShift,
			Checks : []
		}

		if (!_.isUndefined(data)) {
			var points = _.omit(_.clone(data), ["Success", "ErrorCode", "CanCloseShift"]),
				checks = _.map(points, function (item, check) { 
					return _.extend(item, { Id : check }); });

			this.CheckShift.Checks = _.filter(checks, { Has : true });
		}
	}

	/**
	 * Функция, собирающая данные пользователя с формы логина
	 * @param {string} id название параметра
	 * @param {string} value значение параметра
	 * @value LoginUser
	 */
	collectLoginUser (id, value, type) { 
		if (type === "login") this.LoginUser[id] = value; 
		if (type === "recover") this.RecoverUser[id] = value;
		if (type === "register") this.RegisterUser[id] = value;
	}

	/** Функция, собирающая авторизационные данные пользователя для запроса к серверу
	 * @return {object} данные пользователя
	 */
	getLoginUser (type) {
		var data = type === "login" ? _.clone(this.LoginUser) : _.clone(this.RecoverUser);
		return _.extend(data, { Login : data.Login.replace(/\D/gi,"") });
	}
	
	/** Сброс авторизационных данных пользователя */
	resetLogin () {
		this.LoginUser = { Login : "", Password : "" };
		this.RecoverUser = { Login : "" };
		this.LoginError = "";
		this.RetriesLeft = 0;
	}

	/** Проверка корректности заполнения формы
	 * 1. Все поля обязательны для заполнения
	 * 2. Поля первого шага обязательны для перехода на следующий шаг
	 * 3. Номер телефона должен быть валидным
	 * 4. Email должен быть валидным
	 * @returns {boolean}
	 */
	get validateRegister () {
		// var isValid = {
		// 	step1 : true,
		// 	step2 : true
		// }
		// var isValid = true;

		if (this.RegisterUser.MerchantName.length < 4) return false;
		if (this.RegisterUser.UserFirstName.length < 3) return false;
		if (this.RegisterUser.UserPhone.replace(/\D/gi,"").length < 11) return false;
		if (!/.+@.+\..+/i.test(this.RegisterUser.UserEmail)) return false
		if (this.RegisterUser.CityId === -1 || this.RegisterUser.CityId === "") return false;
		// if (this.RegisterUser.FilialName.length < 4) isValid.step2 = false;
		// if (this.RegisterUser.FilialAddress.length < 4) isValid.step2 = false;

		return true;
	}

	/** Сборка регистрационных данных для сервера
	 * @returns {object} для отправки на сервер
	 */
	getRegisterUser () {
		var data = _.clone(this.RegisterUser);
		return _.extend(data, { UserPhone : data.UserPhone.replace(/\D/gi,"") });
	}
}
decorate(AuthUserState, {
	IsAuth: observable,
	IsCheckAuth: observable,
	IsRecoverPass: observable,
	
	User: observable,
	Shift: observable,
	Role: observable,
	Filial: observable,
	Stocks: observable,
	City: observable,
	DashboardId: observable,
	SessionEnd: observable,
	Units: observable,
	CassaShifts: observable,

	LoginUser: observable,
	RecoverUser: observable,
	LoginError: observable,
	RetriesLeft: observable,
	ButtonLoading: observable,
	RegisterUser: observable,
	AllCities : observable,
	RegisterStep : observable,

	CheckShift: observable,

	Roles : computed,
	Merchants: computed,
	Filials : computed,
	Cities : computed,
	Dashboards: computed
});
export const authUserState = new AuthUserState();

class ConfirmState {
	ConfirmOpen = false;
	ConfirmText = "";
	ConfirmComment = "";
	ButtonLoading = false;
	ConfirmSubmit;

	/**
	 * Открытие окна для подтверждения действия
	 * @param {string} text текст подтверждения
	 * @param {function} onConfirm коллбэк функция для подтверждения
	 * @param {string} comment коммент к тексту
	 * @param {function} onCancel коллбэк функция для отмены
	 */
	openConfirm(text, onConfirm, comment, onCancel) {
		this.ButtonLoading = false;

		this.ConfirmText = text;
		this.ConfirmSubmit = onConfirm;
		this.ConfirmCancel = onCancel;
		this.ConfirmComment = comment ? comment : "";
		this.ConfirmOpen = true;
	}

	/** Закрытие окна подтверждения */
	closeConfirm() {
		this.ConfirmText = "";
		this.ConfirmComment = "";

		this.ConfirmSubmit = undefined;
		this.ConfirmCancel = undefined;
		this.ConfirmOpen = false;

		this.ButtonLoading = false;
	}

	/** Вызов функции подтверждения */
	submitConfirm () {
		this.ButtonLoading = true;

		this.ConfirmSubmit();
		this.closeConfirm();
	}
}
decorate(ConfirmState, {
	ConfirmOpen: observable,
	ConfirmText: observable,
	ConfirmComment: observable,
	ButtonLoading: observable,
	
	ConfirmSubmit: observable,
	ConfirmCancel: observable,
});
export const confirmState = new ConfirmState();

class ErrorState {
	ErrorText = null;
	ErrorType = true;
	OnClose;

	/**
	 * Открытие окна с ошибкой
	 * @param {string} code код ошибки, текст берется из словаря ошибок, параметр text не должен передаваться
	 * @param {function} onClose коллбэк функция на закрытие окна с ошибкой
	 * @param {string} text текст ошибки
	 */
	setError(code, onClose, text, type = "error") { 
		this.ErrorText = _.indexOf([null, "", undefined], text) === -1 ? text :
			_.indexOf([null, "", undefined], code) === -1 ? !_.isUndefined(errors[code]) ?
			errors[code] : errors.DEFAULT : null;
		this.ErrorType = type !== "success";
		
		if (onClose) this.OnClose = onClose;
	}
}
decorate(ErrorState, {
	ErrorText: observable,
	ErrorType: observable,
	OnClose: observable
});
export const errorState = new ErrorState();

class PushState {
	Push = [];

	/**
	 * Отображени PUSH сообщения
	 * @param {object} push параметры сообщения
	 */
	setPush(push) { this.Push = _.concat(this.Push, push); }
}
decorate(PushState, {
	Push: observable
});
export const pushState = new PushState();
