import _ from 'lodash';
import moment from 'moment';
import { authUserState } from './stores/common';
import { errors, phoneMasks } from './dictionary';

/**
 * Функция, отправляющая POST запрос
 * @param {string} request название запроса
 * @param {object} params параметры запроса
 * @param {function} callback функция коллбэк
 */
export function getRequest(request, params, callback) {
	fetch('/api/' + request, {
		method: 'POST',
		credentials: "same-origin",
		body: urlParams(params)
	}).then(function (response) { return response.json(); }).then(function (data) {
		if (!checkUserAuth(data.ErrorCode)) if (callback) callback(data);
	});
}

/**
 * Функция, отправляющая запрос с загрузкой файлов
 * @param {string} request название запроса
 * @param {object} params параметры запроса
 * @param {function} callback функция коллбек
 */
export function getUpload(request, params, callback) {
	var data = new FormData();

	_.each(params, function (value, name) { data.append(name, value); });

	fetch('/api/' + request, {
		method: 'POST',
		credentials: "same-origin",
		body: data
	}).then(function (response) { return response.json(); }).then(function (data) {
		if (!checkUserAuth(data.ErrorCode)) if (callback) callback(data);
	});
}

/**
 * Функция, отправляющая POST запрос на скачивание файла
 * @param {string} request название запроса
 * @param {object} params параметры запроса
 * @param {string} filename название скачиваемого файла
 */
export function getFile(request, params, filename) {
	fetch('/api/' + request, {
		method: 'POST',
		credentials: "same-origin",
		body: urlParams(params)
	}).then(function (response) { return response.blob() }).then(blob => {
		const a = document.createElement('a');
		a.href = window.URL.createObjectURL(blob);
		a.download = filename;

		document.body.appendChild(a);
		a.click();
		a.remove();
	});
}
// TODO - проверить используется ли где то еще или можно грохнуть
export function getLists(list, model, type, params) {
	var p = { Active: true };

	fetch('/api/' + list, {
		method: 'POST',
		credentials: "same-origin",
		body: urlParams(params ? params : p)
	}).then(function (response) { return response.json(); }).then(function (data) {
		if (!checkUserAuth(data.ErrorCode))
			model.setState({
				[type ? type : list]: data.Success ? data[type ? type : list] : [],
			});
	});
}
// TODO - проверить используется ли где то еще или можно грохнуть
export function removeId(id, model) {
	if (!_.isUndefined(id)) model.setState({ isConfirmState: true, DeletedItem: id });
}

/**
 * Функция, сериализующая параметры для POST запроса
 * @param {object} params параметры запроса
 * @return {object} сериализованные параметры
 */
export function urlParams(params) {
	const searchParams = new URLSearchParams();
	for (const prop in params) { searchParams.set(prop, params[prop]); }

	return searchParams;
}

/**
 * Функция, проверяющая у пользователя наличие права или что пользователь админ
 * @param {string} right название права
 * @return {boolean} флаг наличия права
 */
export function checkRights(right) {
	return authUserState.User.IsAdmin ? true : _.indexOf(authUserState.User.Rights, right) !== -1;
}

/**
 * Функция, проверяющая у предприятия доступа к модулю
 * @param {string} module название модуля
 * @return {boolean} флаг наличия модуля
 */
 export function checkModule(module) {
	return _.indexOf(authUserState.Modules, module) !== -1;
}

/**
 * Функция, проверяющая у предприятия доступа к рабочему столу (дашборду)
 * @param {string} module название рабочего стола (дашборда)
 * @return {boolean} флаг наличия доступа (дашборда)
 */
 export function checkBoard(board) {
	var routes = _.map(authUserState.Dashboards.Menu, function(v) {
			return v.Name !== "" ? v.Name : v.SubGroup !== "" ? v.SubGroup : v.Group;
		});
	return _.indexOf(routes, board) !== -1;
}

/**
 * Функция, проверяющая окончание сессии
 * @param {string} errCode код ошибки
 * @return {boolean} флаг окончания сессии
 */
export function checkUserAuth(errCode) {
	if (errCode === "USER_NOT_AUTH") authUserState.SessionEnd = true;
	return errCode === "USER_NOT_AUTH";
}

/**
 * Функция, пересобирающая массив в формат для загрузки в контрол Selectize
 * @param {array} list исходный массив { Id, Name}
 * @return {array} форматированный массив {Key, Value }
 */
export function toSelectList (list) {
    if (_.isUndefined(list) || _.isNull(list)) return [];
    return _.map(_.compact(list), function (v) { return { Key : v.Id || v, Value : v.Name || v }; });
}

/**
 * Функция, пересобирающая массив в формат для загрузки в контрол CheckGroup
 * @param {array} list исходный массив
 * @return {array} форматированный массив
 */
export function toCheckList (list) {
    if (_.isUndefined(list) || _.isNull(list)) return [];
    return _.map(_.compact(list), function (v) { return { 
		Id : !_.isUndefined(v.Id) ? v.Id : v, 
		Title : v.Name || v,
		Description : v.Description ? v.Description : undefined,
		Disabled : v.Disabled ? v.Disabled : false
	}; });
}

/**
 * Функция, переводящая копейки в рубли
 * @param {number} amount сумма в копейках
 * @param {boolean} withFormating флаг форматирования получившийся суммы
 * @param {boolean} showNull флаг отображения суммы если она равна «0»
 * @param {string} sep разделитель для форматирования
 * @param {*} account //TODO что это за параметр
 * @param {boolean} cover обертка для копеек
 * @return {number|string} сумма в рублях. формат зависит от флага форматирования
 */
export function getRubs (amount, withFormating, showNull, sep, account, cover) {
    return _.isUndefined(amount) ? showNull ? 0 : "" : withFormating ? account 
        ? formatInt((amount/100).toFixed(2), sep).replace(".", ",") : formatInt((amount/100).toFixed(2), sep, cover)
        : (amount/100).toFixed(2);
}

/**
 * Форматирование суммы «1 234.00»
 * @param {number} number сумма в рублях
 * @param {string} separator разделитель, по умолчанию « »
 * @param {boolean} cover обертка для копеек
 * @return {string} форматированная сумма
 */
function formatInt(number, separator, cover) {
	if (_.isNull(number) || _.isUndefined(number)) return "0.00";

	separator = _.isUndefined(separator) ? " " : separator;

	var str = number.toString(),
		point = /(\d+)(\.\d{1,2})/g.exec(str) ? /(\d+)(\.\d{1,2})/g.exec(str)[2] : "";
	str = /(\d+)(\.\d{1,2})/g.exec(str) ? /(\d+)(\.\d{1,2})/g.exec(str)[1] : str;
	str = str.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + separator);
return (number < 0 ? "-" : "") + str + (cover ? "<span>" + point + "</span>" : point);
}

/**
 * 
 * @param {*} productsList 
 */
// export function groupProducts(productsList) {
// 	return _.map(_.groupBy(productsList, "Type"), function (list, type) {
// 		var sections = _.groupBy(list, "SectionId");
// 		return {
// 			Type: type,
// 			Sections: _.map(sections, function (products, section) {
// 				var amounts = _.groupBy(_.compact(_.map(products, "Amount")), function (v) { return v > 0 }),
// 					vAmounts = _.groupBy(_.compact(_.map(products, "VirtualAmount")), function (v) { return v > 0 });
				
// 				return {
// 					SectionId: parseInt(section),
// 					SectionName: products[0].Section,
// 					AmountPos: _.sum(amounts.true),
// 					AmountNeg: _.sum(amounts.false),
// 					VirtualAmountPos: _.sumBy(vAmounts.true),
// 					VirtualAmountNeg: _.sumBy(vAmounts.false),
// 					Products: products
// 				}
// 			})
// 		};
// 	});
// }

/**
 * 
 * @param {*} productsList 
 * @param {*} clearEmpty 
 */
export function compactProducts(productsList, clearEmpty) {
	var products = _.flatten(_.map(productsList, function (v) {
		return _.flatten(_.map(v.Sections, function (w) { return w.Products; }))
	}));

	if (clearEmpty) return _.filter(products, function (v) { return v.Count !== ""; });
	else return products;
}

/**
 * Функция, округляющая кол-во единиц товара, в зависимости от ЕИ
 * @param {number} count количество товара
 * @param {string} unit название единиц измерения
 * @return {string} форматированная строка «{N.NNN} кг»
 */
export function setUnit(count, unit) {
	if (_.isUndefined(count) || count === "") return "";
	if (!_.isNumber(count)) return count + " " + unit;
	
	var round = unit === "шт" ? _.isInteger(count) ? 0 : 1 : 3;
	return count !== "" ? count.toFixed(round) + " " + (_.isUndefined(unit) ? "" : unit) : "";
}

/**
 * Функция, форматирующая дату в формате «YYYY-MM-DD» или moment в заданный формат
 * @param {string} date дата в формате «YYYY-MM-DD» или moment или «today»
 * @param {string} pattern формат для форматирования даты, по умолчанию «DD.MM.YYYY», «r»=«"YYYY-MM-DD"», «datetime»=«DD.MM.YYYY HH:mm», «time»=«HH:mm»
 * @return {string} форматированная дата
 */
export function formatDate(date, pattern) {
	if (_.isUndefined(pattern)) pattern = "DD.MM.YYYY";
	else if (pattern === "r") pattern = "YYYY-MM-DD";
	else if (pattern === "rtime") pattern = "YYYY-MM-DD HH:mm";
	else if (pattern === "datetime") pattern = "DD.MM.YYYY HH:mm";
	else if (pattern === "time") pattern = "HH:mm";
	else if (pattern === "timedate") pattern = "HH:mm DD.MM.YYYY";

	if (_.isUndefined(date) || date === "") return "";
	else if (date === "today") return moment().format(pattern);
	else if (moment.isMoment(date)) return date.format(pattern);
	else if (date.split("-").length !== 1 && moment(date).isValid()) return moment(date).format(pattern);
	else return date;
}

/**
 * Функция, подбирающая подписи к числу с учетом правильного склонения
 * @param {number} count количество
 * @param {[string]} labels варианты подписей
 * @return {string} форматированная строка «5 яблок»
 */
export function setLabel(count, labels) {
	if (_.isUndefined(count)) return "0 " + labels[2];
	
	var index = 2;
	if (count % 10 === 1) index = 0;
	else if (count % 10 > 1 && count % 10 < 5) index = 1;

	return count + " " + labels[index];
}

/**
 * Функция, форматирующая номер телефона под маску, принятую в указанной стране
 * @param {string} phone номер телефона (только цифры)
 * @param {string} country название страны
 * @return {string} форматированный номер телефона
 */
export function parsePhoneMask(phone, country = "Россия") {
	if (_.isUndefined(phone) || _.isUndefined(country) || _.isUndefined(phoneMasks[country])) return phone;
	var isMask = phoneMasks[country].exec(phone);
	return _.isNull(isMask) ? phone : _.tail(isMask).join(" ");
}

/**
 * Функция, форматирующая номер карты
 * @param {string} card маскированный номер карты
 * @return {string} форматированный номер карты
 */
 export function parseCardMask(card) {
	if (_.isUndefined(card)) return card;
	var isMask = /(\d{4})(\d{2})(\D{6})(\d{4})/.exec(card);
	return _.isNull(isMask) ? card : _.tail(isMask).join(" ");
}

// TODO проверить где используется и нельзя заменить форматированием даты (orderstatus time)
/**
 * Функция, устанавливающая «0» перед числов для форматов времени/даты
 * @param {number} number 
 * @return {string} числос лидирующим «0»
 */
export function setZero(number) { return number.toString().length < 2 ? "0" + number : number.toString(); }

/**
 * Функция, конвертирующую двоичное число в массив из соответствующих ID
 * @param {number|string} number 10-е число, для перевода в двоичный код или двоичное число
 * @return {[number]} массив «активных» разрядов двоичного числа
 */
export function intToBitArray (number) {
	if (!_.isNumber(number) && !_.isString(number)) return [];

	if (_.isNumber(number)) var typeString = _.padStart((number).toString(2), 8, "0");
	else typeString = number

	return _.filter(_.map(_.reverse(typeString.split("")), function (v, i) {
		return v === "0" ? undefined : i
	}), function (v) { return !_.isUndefined(v); });
}

/**
 * Функция, концертирующая массив из ID в соответствующее двоичное число
 * @param {[number]} array массив ID 
 * @param {number} count длинна числа
 */
export function arrayToBitString (array, count) {
	return _.reverse(_.map(_.fill(Array(count), 0), function (v,i) {
		return _.indexOf(array, i) !== -1 ? 1 : 0
	})).join("");
}

/**
 * Функция, возвращающая статус в зависимости от системной настройки статуса
 * @param {[object]} list список статусов заказа
 * @param {number} merchant ID предприятия
 * @param {number} posType тип точки продаж (1 - зал, 2 - самовывоз, 3 - доставка)
 * @param {number} system системная настройка статуса
 * @returns {object} искомый статус
 */
export function getStatusBySystem (list, merchant, posType, system) {
	return _.find(list, function (status) {
		// if (system !== 1 && _.indexOf(status.Merchants, merchant) === -1) return false;
		if (!_.isUndefined(merchant) && _.indexOf(status.Merchants, merchant) === -1) return false;
		if (!_.isUndefined(posType) && _.indexOf(status.POSType, posType) === -1) return false;
		
		return _.indexOf(intToBitArray(status.StatusType), system) !== -1 ? true : false;
	});
}

/**
 * Функция, перводящая минуты в часы и минуты с соответствующими подписями
 * @param {number} minutes 
 * @return {string} форматированная строка «{h} час {m} мин»
 */
export function getHourTime (minutes) {
	if (minutes === "" || _.isUndefined(minutes)) return "";
	
	return minutes === 0 ? "0 м" : Math.trunc(minutes/60) > 0 ? Math.trunc(minutes/60) + " ч " + 
		(minutes % 60) + " м" : minutes + " м";
}

/**
 * Функция получения сдвига по часовому поясу между городом пользователя и городом события
 * @param {number} cityId ID города
 * @return {number} сдвиг в минутах
 */
export function getLocalShift (cityId) {
	var id = cityId !== -1 ? cityId : authUserState.City,
		city = _.find(authUserState.Cities, { Id : id }),
		localUTC = moment().utcOffset();
	return !_.isUndefined(city) ? (city.TimeZone*60 - localUTC) : 0;
}
/**
 * Форматирование BaseString
 * @param {string} base64String 
 */
export function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');
	const rawData = window.atob(base64);
	return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

/**
 * Функция, конвертирующая HEX цвет в RGBA с заданной прозрачностью
 * Если цвет белый, прозрачность не применяется.
 * @param {string} hexCode цвет HEX
 * @param {number} opacity прозрачность
 */
export function convertHex (hexCode,opacity) {
	if (hexCode === "#FFFFFF" || hexCode === "#FFF") return "#FFFFFF";
	if (_.isUndefined(hexCode) || hexCode === "") return "#FFFFFF";

	var hex = hexCode.replace('#','');

	if (hex.length === 3) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}

	var r = parseInt(hex.substring(0,2), 16),
		g = parseInt(hex.substring(2,4), 16),
		b = parseInt(hex.substring(4,6), 16);

	return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity/100 + ')';
}

/**
 * Функция, форматирующая объект адреса по полям в строку
 * @param {object} address объект адреса
 * @param {string} pattern строка, к которой необходимо привести адрес
 */
export function addressToPatternString (address, pattern) {
	if (_.isUndefined(address)) return "";
	if (_.isUndefined(pattern)) pattern = "%%CC%{ %%ss%}, %%SS%, дом %%HH%{, %%HB%}{, кв %%FF%}";

	var params = { CC : "City", ss : "Settlement", SS : "Street", HH : "House", HB : "HouseBuilding", 
			FF : "Flat", ee : "Entrance", ff : "Floor", ii : "Intercom" },
		addressString = pattern,
		components = addressString.match(/(\{.*?%%[\w]+%.*?\})/g);

	if (!_.isNull(components)) _.each(components, function (opt) {
		var code = opt.match(/(%%(\w{2})%)/g)[0].replace(/%/g, ""),
			param = params[code];
			
		if (_.isUndefined(param) || _.isUndefined(address[param]) || address[param] === "" || address[param] === 0 || address[param] === "0")
			addressString = addressString.replace(opt, "");
	});

	_.each(params, function (value, sample) {
		var item = "%%" + sample + "%";
			
		if (addressString.indexOf(item) !== -1) 
			addressString = addressString.replace(item, address[value]);
	});

	addressString = addressString.replace(/\{|\}/g, "");

	return addressString;
}

/**
 * Функция, убирает пустые параметры из запроса 
 * @param {object} params параметры
 * @param {[]} clear данные для очистки, по умолчанию [false, -1, ""]
 * @return {object} объект с непустыми значениями
 */
export function clearEmptyParams (params, clear) {
	if (_.isUndefined(clear)) clear = [false, -1, ""];

	return _.omitBy(_.clone(params), function (v) { return _.indexOf(clear, v) !== -1; });
}

/**
 * Функция, обрабатывающая сбор данных с checkbox
 * @param {string|number} item выбранный элемент
 * @param {boolean} checked добавить или удалить из списка
 * @param {[string|number]} list список
 * @returns {[string|number]} новый список 
 */
export function collectCheckBox (item, checked , list) {
	if (_.isUndefined(item) || item === "") return list;
	if (checked) return _.concat(list, item);
	else return _.without(list, item);
}

/**
 * Функция, обрабатывающая сбора группы данных с checkbox
 * @param {[string|number]} items выбранные элементы
 * @param {boolean} checked добавить или удалить из списка
 * @param {[string|number]} list список
 * @returns {[string|number]} новый список 
 */
export function collectArrayCheckBox (items, checked , list) {
	if (_.isUndefined(items) || _.isEmpty(items)) return list;
	if (checked) return _.uniq(_.concat(list, items));
	else return _.filter(list, function (v) { return _.indexOf(items, v) === -1; });
}

/**
 * Функция, возвращающая цвет «светофора» в зависимости от оставшегося времени
 * @param {number} left сколько осталось минут до подачи
 * @param {number} referenceTime эталонное время подачи
 * @returns {string} цвет «светофора»
 */
export function getTrafficLight(left, referenceTime) {
	return left < 0 ? "red" : left <= referenceTime / 2 ? "orange" : left <= referenceTime ? "yellow" : "green";
}

/**
 * Фнукция, собирающая объект в строку 
 * @param {object} data параметры
 * @returns {string} строка вида key1=value1&key2=value2
 */
export function objectToString(data) {
	return _.map(data, function(value, key) { return key + "=" + value; }).join("&");
}

/**
 * Функция разбирающая строку вида key1=value1&key2=value2 в объект
 * @param {string} data строка с параметрами вида key1=value1&key2=value2
 * @returns {object} объект с параметрами
 */
export function stringToObject(data) {
	if (_.isUndefined(data) || data === "") return {};
	if (_.indexOf(data, "?") === 0) data = data.replace("?", "");

	var paramsArray = data.split("&"),
		paramsObject = {};

	_.each(paramsArray, function(item) {
		var values = item.split("=");

		if (values[0] && values[1]) {
			var isDate = /Date/.test(values[0]),
				isNumber = /^(\d|\.)+$/.test(values[1]),
				isFalse = values[1] === "false",
				isTrue = values[1] === "true"

			paramsObject[values[0]] = isFalse ? false : isTrue ? true : isNumber 
				? parseFloat(values[1]) : values[1];
		}
	});

	return paramsObject;
}

/**
 * Расчет КБЖУ на вес продукта
 * @param {number} param параметр КБЖУ
 * @param {number} weight вес продукта в граммах
 * @return {number} параметр на вес продукта
 */
export function getCPFC (param, weight) { return (param*weight/100).toFixed(0) }

/**
 * Получение текста ошибки по коду
 * @param {string} code код ошибки от сервера
 * @returns Текст ошибки по коду или дефолтная ошибка
 */
export function getErrorText (code) {
	return _.isUndefined(code) || code === "" ? errors.DEFAULT : !_.isUndefined(errors[code]) ? errors[code] : errors.DEFAULT;
}

export function sendMessageRequest(params, callback) {
	fetch("https://anylapus.planfix.ru/webhook/json/8dcg-hhos-gn1m-bmid", {
		method: 'POST',
		credentials: "same-origin",
		body: JSON.stringify(params)
	}).then(function (response) { return response.json(); }).then(function (data) {
		if (callback) callback(data);
	});
}